import React from 'react'

export default function Categories(props) {
    return (<>
      
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>        
        <div className="col-auto my-2">
            <div className="card">
                <a href='/' alt="..."><img src={require('../../assets/images/logo.png')} className="card-img-top p-2" alt="..." />   </a>             
            </div>
        </div>


  </>); 

}