import React from 'react';
import './Home.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Product } from '../Components/component.export';
import { FaPlay } from 'react-icons/fa';
import Categories from '../Components/Product/Categories';
import Filters from '../Components/Product/Filters';
import Sorting from '../Components/Product/Sorting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShippingFast,faUndo,faHandsHoldingCircle,faMedal } from '@fortawesome/free-solid-svg-icons'



function Category_products() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }   
  ]
   
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  }
  const feedCarousel = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  }
  const checkEmail = () => {
    // console.log("UP Clicked");
}
  return (
    
    <div className="homeContent pt-4">      
      <section className='productSection mb-4'>        
        <div className='container-fluid'> 
          
          <div className="row mb-4">
            <Categories />
          </div>
          <div className="row g-3 mb-4">
            <Filters />
          </div>
          <hr />
          <Sorting/>
          <hr/>          
            <div className='row '>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>            
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
              <div className='productWrapper col-md-3 '>
                <Product/>
              </div>
            </div>
          
        </div>
      </section>

      <section id="bannerAdv" className='bannerAdvSection'>
        <div className="container">
          <Slider {...feedCarousel} className="Feeds-owl">
            <div className="item">
                <div className="row">
                    <div className="col-md-6">
                            <div className="item-img">
                              <div className="item-img-txt text-start justify-content-start">
                                  <h4>Commodo Muso Magna Cosmopolis</h4>
                                  <h5>December 10, 2021</h5>
                                  <p>Nam tempus turpis at metus scelerisque placerat nulla deumantos sollicitudin
                                      felis. Pellentesque diam dolor, elementum et lobortis at, mollis ut risus.
                                      Sedcuman Nam tempus turpis at metus scelerisque placerat nulla deumantos
                                      sollicitudin felis. Pellentesque diam dolor, elementum et lobortis at, mollis ut
                                      risus. Sedcuman des... des...</p>
                                  <div className='ps-4'><button className='btn btn-primary' >SHOP NOW</button></div>
                              </div>
                            </div>
                        
                    </div>
                    <div className="col-md-6 feedVideoWrap">
                        <div className="item-video">                              
                            <span><FaPlay/></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="row">
                    <div className="col-md-6">
                            <div className="item-img">
                              <div className="item-img-txt">
                                  <h4>Commodo Muso Magna Cosmopolis</h4>
                                  <h5>December 10, 2021</h5>
                                  <p>Nam tempus turpis at metus scelerisque placerat nulla deumantos sollicitudin
                                      felis. Pellentesque diam dolor, elementum et lobortis at, mollis ut risus.
                                      Sedcuman Nam tempus turpis at metus scelerisque placerat nulla deumantos
                                      sollicitudin felis. Pellentesque diam dolor, elementum et lobortis at, mollis ut
                                      risus. Sedcuman des... des...</p>
                                      <div className='ps-4'><button className='btn btn-primary' >SHOP NOW</button></div>
                              </div>
                            </div>
                        
                    </div>
                    <div className="col-md-6 feedVideoWrap">
                        <div className="item-video">                              
                            <span><FaPlay/></span>
                        </div>
                    </div>
                </div>
            </div> 
          </Slider>
            
        </div>
      </section>

      <section className='productSection mb-4'>
        <div className='container-fluid'>
          <div className='commonHeading'>
            <h2><span>BEST SELLING PRODUCTS</span></h2>
            <a href='/' className='viewAllBtn'>VIEW ALL</a>
          </div>
          <Slider {...settings}>
            <div className='productWrapper'>
              <Product/>
            </div>            
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
          </Slider>
          
        </div>
      </section>
      <section className='productSection mb-4' style={{backgroundColor:'#e0e8f5',}}>
        <div className='container-fluid'>
          <div className='commonHeading pt-2 mb-4'>
            <h2><span>You May Also Like</span> Based On Your Recent Views</h2>            
          </div>
          <Slider {...settings}>
            <div className='productWrapper'>
              <Product/>
            </div>            
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>
            <div className='productWrapper'>
              <Product/>
            </div>           
          </Slider>
          
        </div>
      </section>
      
      <section className='shippingSec mb-4'>
        <div className="container ">
          <div className="row row-cols-1 row-cols-md-4 py-5">

            <div className="col">
              <div className="card border-0">                
                <FontAwesomeIcon icon={faShippingFast} className="pt-2 fs-1 " style={{'color':'#870809'}} />
                <div className="card-body text-center">
                  <h5 className="card-title">FREE SHIPPING & RETURNS</h5>
                  <h6 className="card-title text-muted pb-2">All Orders Over $99</h6>
                  <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">                
                <FontAwesomeIcon icon={faUndo} className="pt-2 fs-1 " style={{'color':'#870809'}} />
                
                <div className="card-body text-center">
                  <h5 className="card-title">FREE RETURN</h5>
                  <h6 className="card-title text-muted pb-2">Within 30 days</h6>
                  <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">                
                <FontAwesomeIcon icon={faHandsHoldingCircle} className="pt-2 fs-1 " style={{'color':'#870809'}} />
                <div className="card-body text-center">
                  <h5 className="card-title">SECURE PAYMENT</h5>
                  <h6 className="card-title text-muted pb-2">100% secure payment</h6>
                  <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">                
                <FontAwesomeIcon icon={faMedal} className="pt-2 fs-1 " style={{ 'color': '#870809' }} />                
                <div className="card-body text-center">
                  <h5 className="card-title">BEST PRICE</h5>
                  <h6 className="card-title text-muted pb-2">Guaranteed Price</h6>
                  <p className="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>

    </div>
  );
}

export default Category_products;
