import React from 'react';
import { Footer, Header, AppBody  } from '../Components/component.export';



function DefaultLayout(props) {  
    return (
      <>
        <Header />
        <AppBody pageType={props.pageType} />
        <Footer />
      </>
    );

}

export default DefaultLayout;
