import React from "react";
import "./Home.scss";
import featureBanner1 from "../assets/images/feture-banner1.jpg";
import featureBanner2 from "../assets/images/feture-banner2.jpg";
import featureBanner3_ from "../assets/images/feture-banner3_.jpg";
import featureBanner4_ from "../assets/images/feture-banner4_.jpg";
import featureBanner3 from "../assets/images/feture-banner3.jpeg";
import featureBanner4 from "../assets/images/feture-banner4.jpeg";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Product,
  Product2,
} from "../Components/component.export";
import { FaPlay } from "react-icons/fa";

function Home() {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
   };
  
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    responsive: [
      {
        breakpoint: 2024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
    
    ]
  };
  const settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
  };

  return (
    <div className="homeContent pt-4">
      <section className="homeBanner ">
        <div className="container-fluid">
          <Row>
            <Col lg="12" className="mb-3 mb-lg-0">
              <Carousel fade indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={featureBanner1}
                    alt="First slide"
                    style={{height:"auto"}}
                  />
                  <Carousel.Caption>
                   <p className="Font_style">NEW ARRIVALS</p>
                    {/* <h4 className="">NEW ARRIVALS</h4> */}
                    <p>Checkout the latest brands &amp; styles...</p>
                    <Button>Shop Now</Button>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={featureBanner2}
                    alt="Second slide"
                    style={{height:"auto"}}
                  />

                  <Carousel.Caption>
                  <p className="Font_style">Second slide label</p>
                    {/* <h3>Second slide label</h3> */}
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <Button>Shop Now</Button>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
      </section>
      <section className="productSection mb-5">
        <div className="container-fluid">
          <div className="col-12 d-flex justify-content-center">
            {/* <h2>
              <span>Customized</span> FEATURED PRODUCTS
            </h2> */}
            <h4 className=" fw-300 my-5 d-xl-none d-flex">
                <span className="fw-700  pe-1">CUSTOMIZED</span> FEATURED PRODUCTS
                </h4>
                <h3 className=" fw-300 my-5 d-none d-xl-flex ">
                <span className="fw-700 pe-1">CUSTOMIZED</span> FEATURED PRODUCTS
                </h3>
          </div>
          <Row className="gap-xl-0 gap-md-0 gap-5 ">
            <Col lg="6" >       
              <Carousel  interval={null} indicators={false}>
                <Carousel.Item>
                  <img
                    className=" w-100 d-block"
                    src={featureBanner3}
                    alt="First slide"
                    // style={{height:"635px",marginTop:"65px"}}
                  />
                  <Carousel.Caption>
                    <h3>Customized Product </h3>
                    <p>Welcome to our Marketplace</p>
                    <Button>All Videos</Button>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={featureBanner4}
                    alt="Second slide"

                  />

                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <Button>All Videos</Button>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>

            <Col lg="6" className="ps-4 pe-4">
              <div className="row d-flex align-items-center mb-2 ">
                <h4 className="col-4 my-lg-0 my-xl-3 d-xl-none d-flex">
                  <span>DEALS</span> 
                </h4>
                <h3 className="col-4 my-lg-0 my-xl-3 d-none d-xl-flex">
                  <span>DEALS</span>
                </h3>
                
                <div className=" col-8 my-lg-0 my-xl-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <div className="w-100 p-25">
              <Slider {...settings}>
                <div className="productWrapper" >
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="productSection mb-5">
        <div className="container-fluid">
          <Row className="gap-xl-0 gap-md-0 gap-5 ">
            <Col lg="6" className="pe-4 ps-4">
              <div className="row d-flex align-items-center mb-3">
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>RECOMMENDED SELLERS</span> 
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>RECOMMENDED SELLERS</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product/>
                </div>
                <div className="productWrapper">
                  <Product/>
                </div>
                <div className="productWrapper">
                  <Product/>
                </div>
                <div className="productWrapper">
                  <Product/>
                </div>
              </Slider>
            </Col>

            <Col lg="6" className="ps-4 pe-4 ">
              <div className="row d-flex align-items-center mb-3">
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>SAVED SELLERS</span> 
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>SAVED SELLERS</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product/>
                </div>
                <div className="productWrapper">
                  <Product/>
                 </div>
                <div className="productWrapper">
                  <Product/>
                </div>
                <div className="productWrapper">
                  <Product/>
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
      
      <section className="productSection mb-5">
        <div className="container-fluid">
        <Row>
          <Col lg="12" className="ps-4 pe-4">
          <div className=" mb-3 ">
            <div className="row">
              <h4 className="col-8 fw-300 my-3 d-xl-none d-flex">
                  <span className="fw-700 pe-1">RECENTLY</span> VIEWED PRODUCTS
                </h4>
                <h3 className="col-8 fw-300 my-3 d-none d-xl-flex">
                  <span className="fw-700 pe-1">RECENTLY</span> VIEWED PRODUCTS
                </h3>
              <div className="col-4 my-3 d-flex justify-content-end align-items-center">
                <a href="/" className="viewAllBtnRight">
                  VIEW ALL
                </a>  
              </div>
            </div>
          </div>

          <Slider {...settings2}>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
          </Slider>
          </Col>
          </Row>
        </div>
      </section>

      <section className="productSection mb-5">
        <div className="container-fluid ">
          <div className="col-12 d-flex justify-content-center">
            <h4 className="mb-5 fw-300 d-xl-none d-flex">
                  <span className="fw-700 pe-1">OFFICIAL</span> PRODUCTS 
                </h4>
                <h3 className="mb-5 fw-300 d-none d-xl-flex">
                  <span className="fw-700 pe-1">OFFICIAL</span> PRODUCTS
                </h3>
          </div>
          <Row className="gap-xl-0 gap-md-0 gap-5">
            <Col lg="6" className="">
              <Carousel interval={null} indicators={false}>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={featureBanner3}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3>Customized Product </h3>
                    <p>Welcome to our Marketplace</p>
                    <Button>All Videos</Button>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={featureBanner4}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h3>Second slide label</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <Button>All Videos</Button>
                  </Carousel.Caption>   
                </Carousel.Item>
              </Carousel>
            </Col>

            <Col lg="6" className="ps-4 pe-4">
              <div className="row d-flex align-items-center mb-xl-3 my-md-1">
              <h4 className="col-4 my-lg-0 my-xl-3 d-xl-none d-flex">
                  <span>DEALS</span> 
                </h4>
                <h3 className="col-4 my-lg-0 my-xl-3 d-none d-xl-flex">
                  <span>DEALS</span>
                </h3>
                <div className="col-8 my-lg-0 my-xl-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
      <section className="productSection mb-5">
        <div className="container-fluid">
          <Row className="gap-xl-0 gap-md-0 gap-5 ">
            <Col lg="6" className="ps-4 pe-4 ">
              <div className=" row d-flex align-items-center mb-3">
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>RECOMMENDED SELLERS</span>
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>RECOMMENDED SELLERS</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
            </Col>

            <Col lg="6" className="ps-4 pe-4">
              <div className="row d-flex align-items-center mb-3">
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>SAVED SELLERS</span>
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>SAVED SELLERS</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </section>

      <section className="productSection mb-5">
        <div className="container-fluid">
        <Row>
          <Col lg="12" className="ps-4 pe-4">
          <div className=" mb-3">
            <div className="row d-flex align-items-center">
            <h4 className="col-8 fw-300 my-3 d-xl-none d-flex">
                  <span className="fw-700 pe-1">RECENTLY</span> VIEWED PRODUCTS
                </h4>
                <h3 className="col-8 fw-300 my-3 d-none d-xl-flex">
                  <span className="fw-700 pe-1">RECENTLY</span> VIEWED PRODUCTS
                </h3>
              <div className="col-4 my-3">
                <a href="/" className="viewAllBtnRight">
                  VIEW ALL
                </a>
              </div>
            </div>
          </div>
          <Slider {...settings2}>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
          </Slider>
          </Col>
          </Row>
        </div>
      </section>

      <section className="productSection mb-5">
        <div className="container-fluid">
        <Row>
        <Col lg="12" className="pe-4 ps-4">
          <div className="mb-3">
            <div className="row d-flex align-items-center">
              <h4 className="col-8 fw-300 my-3 d-xl-none d-flex">
                  <span className="fw-700 pe-1">FREQUENTLY</span> SEARCHED PRODUCTS
                </h4>
                <h3 className="col-8 fw-300 my-3 d-none d-xl-flex">
                  <span className="fw-700 pe-1">FREQUENTLY</span> SEARCHED PRODUCTS
                </h3>
            
              <div className="col-4 my-3">
                <a href="/" className="viewAllBtnRight">
                  VIEW ALL
                </a>
              </div>
            </div>
          </div>
          <Slider {...settings2}>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
            <div className="productWrapper">
              <Product />
            </div>
          </Slider>
          </Col>
          </Row>
        </div>
      </section>
      <section className="productSection mb-5">
        <div className="container-fluid">
          <Row className="gap-xl-0 gap-md-0 gap-5">
            <Col lg="6" className="pe-4 ps-4 ">
              <div className="row d-flex align-items-center mb-3">
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>BIDDING</span> 
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>BIDDING</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>  
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
            </Col>

            <Col lg="6" className="ps-4 pe-4">
              <div className="row d-flex align-items-center mb-3">
                {/* <h3 className="col-8 my-3">
                  <span>YOUR BIDS</span>
                </h3> */}
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>YOUR BIDS</span> 
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>YOUR BIDS</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings}>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
                <div className="productWrapper">
                  <Product />
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
      <section className="productSection mb-5 pb-5">
        <div className="container-fluid">
          <Row>
            <Col lg="12" className=" ps-4 pe-4">
              <div className="row d-flex align-items-center mb-3">
                {/* <h3 className="col-8 my-3">
                  <span>MY WATCH LIST</span>
                </h3> */}
                <h4 className="col-8 my-3 d-xl-none d-flex">
                  <span>MY WATCHLIST</span> 
                </h4>
                <h3 className="col-8 my-3 d-none d-xl-flex">
                  <span>MY WATCHLIST</span>
                </h3>
                <div className="col-4 my-3">
                  <a href="/" className="viewAllBtnRight">
                    VIEW ALL
                  </a>
                </div>
              </div>
              <Slider {...settings2}>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
                <div className="productWrapper">
                  <Product2 />
                </div>
              </Slider>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Home;
