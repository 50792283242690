import React, { useState } from 'react';
import productImag from "../../assets/images/product1.png";
import productImag2 from "../../assets/images/product2.jpg";
import "./Product.scss";
import { IoHeartOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai";


function Product2() {
  const [newBigImg, setBigImg] = useState('');
  const [active, setActive] = useState(0);
  const changeBigImage = (prms, i)=>{
    setBigImg(prms);
    setActive(i);
  }
  return (<>
  <div className="pcard">
    <div className='each_product shadow rounded mb-4'>
        <div className='eachProductImage'>
            <div className={'pBadge ' + 'discount'}>-50%</div>
            <img src={newBigImg?newBigImg:productImag} alt=""/>
        </div>
        <div  className='eachProductText ps-3 pe-3 pb-3'>  
            <div className='favouriteStatus'>
                <IoHeartOutline/>
            </div>          
            <h6>Category</h6>
            <h2>Customized Watches</h2>
            <div className='moreFetureImgThumb'>
                <ul>
                    <li className={active===0?"active":""} onClick={()=>{changeBigImage(productImag, 0)}}>
                        <img src={productImag} alt=""/>
                    </li>
                    <li className={active===1?"active":""} onClick={()=>{changeBigImage(productImag2, 1)}}>
                        <img src={productImag2} alt=""/>
                    </li>                   
                </ul>
            </div>
            <h3>$215.00</h3>
           
        </div>
        
    </div>
    </div>
  </>); 
}

export default React.memo(Product2);
