import './App.scss';
import DefaultLayout from './Layout/DefaultLayout';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>    
      <Routes> 
        <Route path="/" element={<DefaultLayout pageType={'home'} />} />
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
