import React from 'react';
import './Footer.scss';
import { Button, Col, Row } from 'react-bootstrap';
import card from "../assets/images/visa.png";
import { FaFacebook, FaFacebookF, FaInstagram, FaSkype, FaTwitter } from 'react-icons/fa';

function Footer() {
  return (<footer className='siteFooter'>
    <div className='newsLetter'>
      <div className='container-fluid'>
        <div className='newsLetterInnr'>
          <Row className='align-items-center'>
            <Col lg="1"></Col>
            <Col lg="6">
              <div className='newletterWrap'>
                <input type={'text'} placeholder="Your email address here.."/>
                <Button className='subScribeNewsletter'>SUBSCRIBE <span>NEWSLETTER</span></Button>
              </div>
            </Col>
            <Col lg="2"></Col>
            <Col lg="2">
              <ul>
                <li>
                  <a href="/"><FaFacebookF/></a>
                </li>
                <li>
                  <a href="/"><FaTwitter/></a>
                </li>
                <li>
                  <a href="/"><FaInstagram/></a>
                </li>
                <li>
                  <a href="/"><FaSkype/></a>
                </li>
              </ul>
            </Col>
            <Col lg="1"></Col>
          </Row>
        </div>
      </div>
    </div>
    <div className="topFooter">
      <div className='container-fluid'>
        <Row>
          <Col lg="3" sm="6" className='mb-4 mb-lg-0'>
            <div className='h4'>Categories</div>
            <ul>
              <li><a href="/">Furniture</a></li>
              <li><a href="/">Clothings</a></li>
              <li><a href="/">Shoes</a></li>
              <li><a href="/">Accessories</a></li>
              <li><a href="/">Electronics</a></li>
              <li><a href="/">Gift</a></li>
            </ul>
          </Col>
          <Col lg="3"  sm="6" className='mb-4 mb-lg-0'>
            <div className='h4'>Information</div>
            <ul>
              <li><a href="/">About Us</a></li>
              <li><a href="/">Contact Us</a></li>
              <li><a href="/">Downloads</a></li>
              <li><a href="/">Sitemap</a></li>
              <li><a href="/">FAQs</a></li>
              <li><a href="/">Privacy Policy</a></li>
            </ul>
          </Col>
          <Col lg="3"  sm="6"  className='mb-4 mb-sm-0'>
            <div className='h4'>Quick Links</div>
            <ul>
              <li><a href="/">Licenses</a></li>
              <li><a href="/">Return policy</a></li>
              <li><a href="/">Payment methods</a></li>
              <li><a href="/">Become a vendor</a></li>
              <li><a href="/">Become an affiliate</a></li>
              <li><a href="/">Marketplace benefits</a></li>
            </ul>
          </Col>
          <Col lg="3"  sm="6" >
            <div className='h4'>Get In Touch With Us</div>
            <ul>
              <li className='mb-3'>Phone: +1 (900) 33 169 7720</li>
              <li className='mb-3'>Monday-Friday: 9.00 am - 8.00 pm <br/>
                  Saturday: 10.00 am - 6.00 pm</li>
              <li>support@demolink.com </li>              
            </ul>
          </Col>
        </Row>
      </div>
    </div>
    <div className="bottomFooter">
      <div className='container-fluid'>
          <Row className='align-items-center'>
            <Col lg="6">
              <p>© 2022 Customized Marketplace. All rights reserved.</p>
            </Col>
            <Col lg="6">
              <ul className='weAcceptCard'>
                <li>We accept :</li>
                <li>
                  <img src={card} alt="" />
                </li>
              </ul>
            </Col>            
          </Row>
      </div>
    </div>
  </footer>);
}

export default React.memo(Footer);
