import React, { useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import "./Header.scss";
import logo from "../assets/images/logo.png";
import { FaHeart, FaSearch } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

function Header() {
  const [menuToggle, SetMenuToggle] = useState(false);
  const toggleMenu = () => {
    SetMenuToggle(!menuToggle);
  };
  return (
    <header className="siteHeader">
      <section className="topHeader">
        <div className="container-fluid">
          <Row className="justify-content-end">
            <Col sm="auto">
              <div className="d-flex justify-content-between topHeaderright">
                <ul>
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Ship to
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/">Action</Dropdown.Item>
                        <Dropdown.Item href="/">Another action</Dropdown.Item>
                        <Dropdown.Item href="/">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <a href="/">Sell</a>
                  </li>
                  <li>
                    <a href="/">Watchlist</a>
                  </li>
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        My eBay
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/">Action</Dropdown.Item>
                        <Dropdown.Item href="/">Another action</Dropdown.Item>
                        <Dropdown.Item href="/">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="/">
                      <img src={require("../assets/images/icon/bell.png")} />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <img src={require("../assets/images/icon/cart.png")} />
                    </a>
                  </li>
                  {/* <li>
                    <a href="/">
                      <img src={require("../assets/images/icon/usa.png")} />
                    </a>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="middleHeader">
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col xs={"auto"}>
              <div className="logo_Wrap">
                <img src={logo} />
              </div>
            </Col>
            <Col>
              <div className="searchWrapper">
                <select className="searchSelect">
                  <option>All Categories</option>
                  <option>Categories 1</option>
                  <option>Categories 2</option>
                  <option>Categories 3</option>
                  <option>Categories 4</option>
                  <option>Categories 5</option>
                </select>
                <div className="searchGroup">
                  <input placeholder="Search..." className="headerSearch" />
                  <Button className="headerSearchBtn">
                    <FaSearch />
                  </Button>
                </div>
              </div>
              <div className="searchMobileBtn">
                <IoSearchOutline />
              </div>
            </Col>
            <Col xs={"auto"}>
              <div className="text-end signInlinkInfo">
                <a href="/">Sign In</a> Or <a href="/">Register</a>
              </div>
              <div className="mobile-menu-btn" onClick={toggleMenu}>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section
        className="bottomHeader"
        style={{ display: menuToggle ? "block" : "none" }}>
        <div className="container-fluid">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/">Category</NavLink>
            </li>
            <li>
              <NavLink to="/">
                <FaHeart /> Saved
              </NavLink>
            </li>
            <li>
              <NavLink to="/">Furniture</NavLink>
            </li>
            <li>
              <NavLink to="/">Electronics</NavLink>
            </li>
            <li>
              <NavLink to="/">Fashion</NavLink>
            </li>
            <li>
              <NavLink to="/">Health &amp; Beauty</NavLink>
            </li>
            <li>
              <NavLink to="/">Sports</NavLink>
            </li>
            <li>
              <NavLink to="/">Accessories</NavLink>
            </li>
            <li>
              <NavLink to="/">Gifts</NavLink>
            </li>
            <li>
              <NavLink to="/">Collectibles &amp; Art</NavLink>
            </li>
            <li>
              <NavLink to="/">Deals</NavLink>
            </li>
            <li>
              <NavLink to="/">Sell</NavLink>
            </li>
            <li>
              <NavLink to="/">Help {"&"} Contact</NavLink>
            </li>
          </ul>
        </div>
      </section>
    </header>
  );
}

export default React.memo(Header);
