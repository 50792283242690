import React from 'react'
import { IoHeartOutline } from "react-icons/io5";
import { AiFillStar,AiOutlineStar } from "react-icons/ai";

export default function Sorting(props) {

    return (
        <>
        <div className="row row-cols-lg-auto g-3 align-items-center">
          <label className="fw-bold fs-5" >Sort By :</label>
          <label className="fw-bold fs-6" >Price : $</label>
          <div className="col">
            <div className="input-group ">
              <input type="number" className="form-control" placeholder="Min" aria-label="Min" style={{width: '100px'}}/>
              <span className="input-group-text">-</span>
              <input type="number" className="form-control" placeholder="Max" aria-label="Max"  style={{width: '100px'}}/>
            </div>
          </div>

          <div className="col-12">
            
            <select className="form-select" id="inlineFormSelectPref">
              <option value="">Price...</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div className="col-12">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="inlineFormCheck"/>
              <label className="form-check-label" htmlFor="inlineFormCheck">Maximum Purchases</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="inlineFormCheck2"/>
              <label className="form-check-label" htmlFor="inlineFormCheck2">Free Shipping</label>
            </div>
          </div>
          <div className="col-12 ">
            <div className='ratingOrderWrap'>
              <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="inlineFormCheck4" />
                  <div className='rating align-items-center pt-1'>
                    <ul>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiOutlineStar/></li>
                    </ul>
                </div>                
              </div> 
              <label className="form-check-label ps-2" htmlFor="inlineFormCheck4"> or more</label>
              
            </div>
          </div>

        </div>
        
        </>
    )
}
