import React from 'react';
import Home from '../View/Home';
import Category_products from '../View/Category_products';
import Best_sellings from '../View/Best_sellings';



function AppBody(props) {
  //console.log(props);
  if(props.pageType=='category_products') {
    return <Category_products />;
  } else if (props.pageType=='home') {
    return <Home />;
  } else {
    return <Home />;
  }
  
}

export default AppBody;

