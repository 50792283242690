import React, { useState } from 'react';
import productImag from "../../assets/images/product1.png";
import productImag2 from "../../assets/images/product2.jpg";
import "./Product.scss";
import { IoHeartOutline } from "react-icons/io5";
import { AiFillStar } from "react-icons/ai";


function Product() {
  const [newBigImg, setBigImg] = useState('');
  const [active, setActive] = useState(0);
  const changeBigImage = (prms, i)=>{
    setBigImg(prms);
    setActive(i);
  }
  return (<>
    <div className=' each_product shadow rounded mb-4'>
        <div className='eachProductImage'>
            <div className={'pBadge ' + 'discount'}>-50%</div>
            <img src={newBigImg?newBigImg:productImag} alt=""/>
        </div>
        <div  className='eachProductText p-3 pt-0 p-md-0 px-md-3 py-md-1'>  
            <div className='favouriteStatus'>
                <IoHeartOutline/>
            </div>          
            <h6>Category</h6>
            <h2>Watches</h2>
            <div className='moreFetureImgThumb'>
                <ul>
                    <li className={active===0?"active":""} onClick={()=>{changeBigImage(productImag, 0)}}>
                        <img src={productImag} alt=""/>
                    </li>
                    <li className={active===1?"active":""} onClick={()=>{changeBigImage(productImag2, 1)}}>
                        <img src={productImag2} alt=""/>
                    </li>                   
                </ul>
            </div>

            <h3>$215.00</h3>
            <div className='ratingOrderWrap'>
                <div className='rating'>
                    <ul>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                        <li><AiFillStar/></li>
                    </ul>
                </div>
                <div className='orderCount pe-1 pt-1'>
                    1022 Orders
                </div>
            </div>

            
        </div>
        
    </div>
  </>); 
}

export default React.memo(Product);
